import * as React from 'react';
import ROModal from 'react-overlays/Modal';
import cn from 'classnames';
import css from './Modal.scss';
import { IconClose } from 'styleguide/icons';
import ModalFooter from './ModalFooter/ModalFooter';

const modalStyle: React.CSSProperties = {
  position: 'fixed',
  zIndex: 1040,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const Backdrop = () => <div className="fixed inset-0 z-40 bg-black opacity-50" />;

// TODO: pulkit. types should be in sync with FileStatus.tsx and Modal.stories
interface Props {
  children?: React.ReactNode;
  onClose?: () => void;
  shown?: boolean;
  className?: string;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  fullWidth?: boolean;
  hideScroll?: boolean;
  disableClickOutside?: boolean;
}

const Modal = ({
  shown,
  children,
  onClose,
  title,
  footer,
  fullWidth,
  hideScroll,
  disableClickOutside,
  ...props
}: Props) => {
  const [modalShown, setModalShown] = React.useState(shown);

  const renderBackdrop = backdropProps => <Backdrop {...backdropProps} />;

  const hideModal = () => {
    setModalShown(false);
    if (onClose) {
      onClose();
    }
  };

  const handleClickOutside = event => {
    let clickInsideSomeModal = false;
    document.querySelectorAll('div[data-modal="modal"]').forEach(element => {
      if (element.contains(event.target)) {
        clickInsideSomeModal = true;
      }
      if (element) {
        const rect = element.getBoundingClientRect();
        const isInModal =
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom;
        if (isInModal) {
          clickInsideSomeModal = true;
        }
      }
    });

    if (!clickInsideSomeModal) {
      if (onClose && !disableClickOutside) {
        onClose();
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    setModalShown(shown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [shown]);

  return (
    <ROModal
      aria-labelledby="modal-label"
      // @ts-ignore
      style={modalStyle}
      show={modalShown}
      renderBackdrop={renderBackdrop}
      onEscapeKeyDown={hideModal}
    >
      <div
        className={cn(css.dialog, props.className, !!fullWidth && '!h-[95%] !max-w-[95%]')}
        data-modal="modal"
      >
        <IconClose size="xs" className={css.close} onClick={hideModal} />
        {!!title && title}
        <div
          className={cn(css.modalContent, !footer && '!pb-0', !!fullWidth && 'h-full !max-h-full', {
            [css.modalContentScroll]: !hideScroll,
          })}
        >
          <div
            className={cn(
              css.modalContentInner,
              hideScroll ? 'overflow-hidden' : 'overflow-auto',
              !footer && '!pb-0',
              !!fullWidth && 'h-full !max-h-full',
            )}
          >
            {children}
          </div>
        </div>
        {!!footer && <ModalFooter>{footer}</ModalFooter>}
      </div>
    </ROModal>
  );
};
export default Modal;
